.exHeader {
  background-color: #5F5C63;
  height: 64px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 0 30px;
  }

  .headerLogo {
    display: flex;
    align-items: center;
  }

  .headerTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
  }

  .usernameText {
    margin-right: 16px;
    margin-left: 40px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .userAvatar {
    margin-right: 20px;

    @media (max-width: 768px) {
      margin-right: 0;
    }

    @media (max-width: 425px) {
      display: none;
    }
  }

  .logOutButton {
    color: white;
    display: flex;
    align-items: center;
    gap: 14px;
    margin-left: 30px;

    @media (max-width: 768px) {
      margin-left: unset;
    }

    @media (max-width: 425px) {
      display: none;
    }
  }

  .burgerMenu {
    display: none;
    font-size: 25px;
    margin-right: 20px;

    @media (max-width: 425px) {
      display: unset;
    }
  }

  .logoutIcon {
    display: none;

    @media (max-width: 425px) {
      display: unset;
    }
  }
}