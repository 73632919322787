.logo {
  padding: 20px;
  color: black;
}

.sideMenuWrapper {
  padding-top: 49px;
  z-index: 100;
  height: calc(100% + 48px);
  border-right: 1px solid #D9DBDB;
  background: #FFFFFF;

  :global(.ant-menu-item) {
    padding-left: 16px !important;
    height: 20px !important;
    margin-bottom: 20px !important;
  }

  :global(.ant-menu-item-selected) {
    border-right: 3px solid #C41D7F;
    margin-inline: unset !important;
    margin-left: 10px !important;
  }

}

:global(.ant-layout-sider-trigger) {
  background: transparent !important;
  color: black !important;
}

:global(.ant-layout-sider-children) {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
