.container {
  min-height: 100vh;
}

.contentWrapper {
  padding: 50px;
}

.sideMenuWrapper {
  padding-top: 49px;
  z-index: 100;
  height: 100%;
  border-right: 1px solid #D9DBDB;

  :global(.ant-menu-item) {
    padding-left: 16px !important;
  }

  :global(.ant-layout-sider-trigger) {
    background: transparent !important;
    color: black !important;
  }
}
